<template>
  <div>
    <CardForm class="mt-6">
      <template #card-form>
        <v-form
          ref="form_work"
          lazy-validation
          v-model="valid"
          @submit.stop.prevent="handleSubmit"
          class="mt-4 pt-2"
        >
          <v-row>
            <v-col
              class="py-0"
              :key="'col-' + ind"
              v-for="(item, ind) in style_form"
              :cols="item.col ? item.col : '6'"
              v-if="item.hasOwnProperty('visible') ? item.visible : true"
            >
              <div v-if="item.value_text == 'work_id'">
                <label
                  class="form-label text-xs mb-4 text-typo font-weight-bolder ms-1"
                  >{{ $t("project.work time") }}</label
                >
                <span v-if="item.req" class="text-danger astrik mr-2">*</span>

                <v-radio-group v-model="worker_count_and_times_id" row>
                  <v-radio
                    v-for="radio in item.items"
                    :key="radio.id"
                    :label="radio.name"
                    color="#345c67"
                    :value="radio.id"
                    @change="select_work(radio)"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div v-else-if="item.value_text == 'count_hour'">
                <v-chip label outlined color="#345c67" class="mt-9 px-2 my-0">
                  <span class="text-sm text-typo ls-0">
                    {{ $t("daily.count_hour") }}
                  </span>
                  <span class="mx-2 text-md font-weight-bold">{{ time }}</span>
                  <v-icon>mdi-clock</v-icon>
                </v-chip>
              </div>
              <div v-else-if="item.value_text == 'hourly_fare'">
                <v-chip
                  label
                  outlined
                  color="#262f68"
                  class="mt-13 mx-3 px-2 my-0"
                >
                  <span class="mx-2 text-md font-weight-bold">{{
                    hourly_fare
                  }}</span>
                  <v-icon size="15">mdi-currency-eur</v-icon>
                </v-chip>
                <v-chip
                  label
                  outlined
                  color="#06ab95"
                  @click="edit_hourly_fare"
                  class="mt-13 px-2 my-0"
                >
                  <v-icon>mdi-pencil</v-icon>
                  <v-tooltip activator="parent" location="top">{{
                    $t("general.edit")
                  }}</v-tooltip>
                </v-chip>
              </div>
              <div class="mb-3" v-else>
                <Input :item="item"> </Input>
              </div>
            </v-col>
            <v-col class="pt-0">
              <Button class="text-end" type="submit" :loader="loader"></Button>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </CardForm>
    <Modal :data="modal_data"> </Modal>
  </div>
</template>
  <script>
import CardForm from "../Components/CardForm";
import Modal from "../Components/Modal";
import dailyEntryService from "../../../services/daily_entry.service";
import Input from "../Components/Input";
import Button from "../Components/Button";
import {
  isFormValidate,
  isValueNotEmpty,
  isDropDownComponent,
} from "@/helper/General";
import mixinInput from "./mixininput";
export default {
  mixins: [mixinInput],
  name: "New-daily-entry",
  components: {
    CardForm,
    Modal,
    Input,
    Button,
  },
  data() {
    return {
      style_form: [
        {
          col: "6",
          type: "autocomplete",
          label: this.$i18n.t("project.project_name"),
          value_text: "project_id",
          items: [],
          value: "",
          title_select: "project_name",
          // type_select: "multiple",
          error: null,
          req: true,
          action: "select_project",
        },
        {
          col: "6",
          type: "date",
          label: this.$i18n.t("date"),
          error: null,
          value_text: "date",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "autocomplete",
          label: this.$i18n.t("project.work time"),
          value_text: "work_id",
          items: [],
          value: [],
          title_select: "name",
          error: null,
          req: true,
          // return_object: true,
        },
        {
          col: "8",
          type: "autocomplete",
          label: this.$i18n.t("employee"),
          value_text: "employee",
          items: [],
          value: [],
          title_select: "full_name",
          return_object: true,
          req: true,
          error: null,
        },
        {
          col: "4",
          type: "text",
          label: this.$i18n.t("employeees.hourly_fare"),
          error: null,
          value_text: "hourly_fare",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "5",
          type: "time",
          label: this.$i18n.t("daily.start_actual_time"),
          error: null,
          value_text: "start_actual_time",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "4",
          type: "time",
          label: this.$i18n.t("daily.end_actual_time"),
          error: null,
          value_text: "end_actual_time",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "3",
          type: "text",
          label: this.$i18n.t("daily.count_hour"),
          error: null,
          value_text: "count_hour",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],

      card: {
        title: this.$i18n.t("CreateDailyEntry"),
        loading: true,
        sub_title: this.$i18n.t("daily.daily entry info"),
      },
    };
  },
  computed: {
    project() {
      return this.style_form.filter((v) => v.value_text == "project_id")[0]
        .value;
    },
  },
  watch: {
    project: {
      handler: function (value) {
        return dailyEntryService.get_data_by_project(value).then(
          (response) => {
            console.log(response);
            this.style_form.map((v) => {
              if (v.value_text == "work_id") v.items = response.data.data;
              return v;
            });
          },
          (error) => {
            console.log(error);

            this.$store.commit("form/SET_NOTIFY", {
              msg: i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        );
      },
    },
  },
  methods: {
    handleSubmit() {
      this.loader = true;
      if (isFormValidate(this.$refs.form_work)) {
        this.loader = false;
        const formData = new FormData();
        this.style_form.map(function (item) {
          if (isValueNotEmpty(item.value)) {
            if (isDropDownComponent(item.value)) {
              var value = item.value;
              for (var i = 0; i < value.length; i++) {
                const val = value[i];
                formData.append(`${item.value_text}[ ${i} ]`, val);
              }
            } else {
              formData.append(item.value_text, item.value);
            }
          }
        });
        formData.append("count_hour", this.time);
        formData.append("hourly_fare", this.hourly_fare);
        formData.append("employee_id", this.employee_id);
        formData.append(
          "worker_count_and_times_id",
          this.worker_count_and_times_id
        );
        return dailyEntryService.store(formData).then(
          (response) => {
            this.set_hourly_fare(0);
            this.$router.push({
              name: "daily_entry",
              params: {
                message: { msg: response.data.message, type: "Success" },
              },
            });
          },
          (error) => {
            console.log(error);
            this.$store.commit("form/SET_NOTIFY", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        );
      } else {
        this.loader = false;
      }
    },

    set_data() {
      this.$store.commit("SET_COLLECTION", "daily_entry");
      this.$store.commit("SET_CARD", this.card);
      this.$store.commit("SET_CARD_LOADING", false);
    },
    get_data() {
      this.$store.commit("SET_CARD_LOADING", true);
      return dailyEntryService.create().then(
        (response) => {
          this.$store.commit("SET_CARD_LOADING", false);
          this.style_form[0].items = response.data.data;
          this.style_form[1].value = response.data.date;
          this.$store.commit("form/SET_FORM_STYLE", this.style_form);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_CARD_LOADING", false);
          this.$store.commit("form/SET_NOTIFY", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
  },
  mounted() {
    this.get_data();
    this.set_data();
    document.title = this.$i18n.t("CreateDailyEntry");
  },
};
</script>
<style scoped>
.v-select.v-text-field input {
  font-size: 20px;
}
</style>
  